<template>
  <CRow>
    <CCol col="12" xl="12">
      <transition name="slide">
        <ACRUDTable
          ref="crud_table"
          :fields="[
            //'id',
            'name',
            helpers.inputType,
            getHelperObject('imputation'),
            getHelperObject('charge_method', null, null, null, true),
            getHelperObject('apply_option', 'apply_to', null, 'details', true),
            'name',
            { key: 'total_fees', label: 'Fees', _classes: 'text-right' },
            helpers.currencyValue,
            helpers.buildings,
            helpers.monthNames,
            { key: 'month_id', _classes: 'hide' },
            helpers.activeBadge
          ]"
          :config="{
            url: 'admin/property_extra_fees',
            route: '/admin/processes/extra_fees',
            name: 'extra fee',
            lname: 'property_extra_fees',
            params: { _lists: 'months,buildings,imputations,property_units' },
            filters: [{ key: 'month_id', multiple: true, default: 'response.data.month_ids', forced: true, list: 'months', callback: this.onGetData, config: { select_all: true } }, { key: 'imputation', config: { select_all: true } }, 'active'] // TODO: Add Building as parameter on Backend
          }"
          @preparedHelpers="onPreparedHelpers"
        />
      </transition>
    </CCol>
  </CRow>
</template>

<script>
import crudMixin from '@/app/views/_mixins/crud-mixin'

export default {
  name: 'ExtraFees',
  mixins: [crudMixin],
  methods: {
    // TODO: create a fixed method ?
    onGetData(month_ids) {
      // if (month_ids) {
      // this.month = { month_id, _period_name: this.getPeriodName(month_id) }
      this.$refs.crud_table.getData(false, { month_ids }, true)
      // }
    }
  }
}
</script>
