var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('CRow',[_c('CCol',{attrs:{"col":"12","xl":"12"}},[_c('transition',{attrs:{"name":"slide"}},[_c('ACRUDTable',{ref:"crud_table",attrs:{"fields":[
          //'id',
          'name',
          _vm.helpers.inputType,
          _vm.getHelperObject('imputation'),
          _vm.getHelperObject('charge_method', null, null, null, true),
          _vm.getHelperObject('apply_option', 'apply_to', null, 'details', true),
          'name',
          { key: 'total_fees', label: 'Fees', _classes: 'text-right' },
          _vm.helpers.currencyValue,
          _vm.helpers.buildings,
          _vm.helpers.monthNames,
          { key: 'month_id', _classes: 'hide' },
          _vm.helpers.activeBadge
        ],"config":{
          url: 'admin/property_extra_fees',
          route: '/admin/processes/extra_fees',
          name: 'extra fee',
          lname: 'property_extra_fees',
          params: { _lists: 'months,buildings,imputations,property_units' },
          filters: [{ key: 'month_id', multiple: true, default: 'response.data.month_ids', forced: true, list: 'months', callback: this.onGetData, config: { select_all: true } }, { key: 'imputation', config: { select_all: true } }, 'active'] // TODO: Add Building as parameter on Backend
        }},on:{"preparedHelpers":_vm.onPreparedHelpers}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }